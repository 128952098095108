;(function (window) {
  var vs = window[window.VStoreObject]
  vs.searchParams = new URLSearchParams(window.location.search)
  vs.version = vs.searchParams.get('_vs_version') || vs.v
  vs.buildVersion = vs.v + '.83cfcc9'
  vs.timestamp = vs.t
  vs.options = {
    enabled: []
  }
  vs.env = process.env.NODE_ENV || 'local'

  // Queue methods
  /**
   * Set a value to options object
   * @param key
   * @param value
   */
  vs.set = function (key, value) {
    vs.options[key] = value
  }

  /**
   * Short version of this.set for packages
   * @param value
   */
  vs.enable = function (value) {
    if (Array.isArray(value)) {
      this.set('enabled', value)
    } else {
      this.set('enabled', [])
    }
  }
})(window)
